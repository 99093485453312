export default {
  data: function data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBillingDialog: false,
      customerLoading: false,
      propertyLoading: false,
      customerDialog: false,
      entityDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false
    };
  },
  methods: {
    selectCustomerDialog: function selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog: function selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog: function selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    selectCustomerBillingDialog: function selectCustomerBillingDialog() {
      this.customerBillingDialog = true;
    }
  }
};